import { deleteHistory, getHistory, clonePlan } from "@/data"
import { State } from "@/store/index"

interface HistoryItem {
  plan: any
  profileFacts: any
}

interface HistoryParams {
  historyId?: string | (string | null)[]
  isDraft?: boolean
  mode: string | null
}

export type HistoryState = {
  history: []
}

export default {
  namespaced: true,

  state: () => ({
    history: []
  }),
  mutations: {
    setHistory(state: HistoryState, history: any) {
      state.history = history
    }
  },
  actions: {
    async getHistory({ rootState, commit }: { rootState: State; commit: Function }) {
      commit("setLoading", true, { root: true })
      const { _id } = rootState

      if (!_id) {
        commit("setHistory", [])

        return
      }
      const response = await getHistory(_id)

      commit("setHistory", response.data)
      commit("setLoading", false, { root: true })
    },
    async deleteHistory(
      { commit, rootState }: { commit: Function; rootState: State },
      { historyId, deleteFromTD }: { historyId: string; deleteFromTD: boolean }
    ) {
      const { token } = rootState
      commit("setLoading", true, { root: true })
      await deleteHistory(historyId, deleteFromTD, token)
      commit("setLoading", false, { root: true })
    },
    async clonePlan(
      { commit, dispatch, rootState }: { commit: Function; dispatch: Function; rootState: State },
      { historyId, startDate }: { historyId: string; startDate?: string }
    ) {
      commit("setLoading", true, { root: true })
      try {
        const { _id } = rootState

        const result = await clonePlan(_id, historyId, startDate)
        return result.data
      } catch (e) {
        console.log(e)
      } finally {
        commit("setLoading", false, { root: true })
        dispatch("getHistory")
      }
    }
  }
}
