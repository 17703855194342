import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import jackRoutes from "./jack"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    redirect: "/history"
  },
  {
    path: "/history",
    name: "history",
    component: () => import(/* webpackChunkName: "History" */ "@/views/history/History.vue")
  },
  {
    path: "/routes",
    name: "routes",
    component: () => import(/* webpackChunkName: "Routes" */ "@/views/routes/Routes.vue")
  },
  ...jackRoutes
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})
// add these to above

export default router
