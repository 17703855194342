import range from "lodash.range"

const granulate = (value, granularityFactor) => {
  return Math.round(value / granularityFactor)
}

export const getTimeArray = (minutes, granularityFactor = 1) => {
  const adder =
    minutes <= 5
      ? granulate(1, granularityFactor)
      : minutes <= 30
      ? granulate(5, granularityFactor)
      : minutes <= 90
      ? granulate(10, granularityFactor)
      : minutes <= 240
      ? granulate(30, granularityFactor)
      : minutes <= 660
      ? granulate(60, granularityFactor)
      : -1
  const ranged = range(0, Math.floor(minutes / adder) * adder + adder, adder)
  ranged.push(minutes - Math.max(...ranged))
  return ranged
}
