export type Answer = {
  text: string
  value: string
  class: string
  isRecommended: boolean
  isDisabled: boolean
  userChoice: boolean
}

export type QuestionType = {
  text: string
  key: string
  type: string
  isDependent: boolean
  dependentQuestions: []
  class: string
  answers: [Answer]
  isAnswered: boolean
  isVisible: boolean
  isRefreshed: boolean
  isRemoved: boolean
  userChoice: string | []
  totalProgress: number
  limit: number
  skipped?: number
  html?: string
  answersNotesHtml?: any

  dropQuestions?: any
}

export const DATE_FORMAT = "YYYY-MM-DD"

export const BLOCK_TYPES = {
  ANY: "any",
  BASE: "base",
  BUILD: "build",
  PEAK: "peak",
  RACE: "race",
  EVENT: "event",
  TRANSITION: "transition"
} as const
export type BlockType = typeof BLOCK_TYPES[keyof typeof BLOCK_TYPES] | null

export const PLAN_TYPES = {
  SWEET_SPOT_HARD: "sweet-spot-hard",
  SWEET_VO2_HARD: "sweet-vo2-hard",
  BIG_INTENSITY: "big-intensity",
  ITALIAN_CLASSIC: "italian",
  CRUNCHED: "crunched",
  POLARIZED: "polarized",
  FITNESS: "fitness",
  SWEET_SPOT: "sweet-spot",
  BASE: "base",
  BASE_PLUS: "base+",
  BASE_PLUS_PLUS: "base++",
  BIG_CLIMBS: "big-climbs",
  BIG_DAYS: "big-days",
  STRONGER_RIDER: "stronger-rider",
  SPRINTING: "sprinting",
  CUSTOM: "custom"
} as const
export type PlanType = typeof PLAN_TYPES[keyof typeof PLAN_TYPES]

export type EventConfig = {
  eventDate: string
  duration?: string
  type?: string
  primary?: boolean
}

export type EventResult = {
  weeksToEvent: number
  dayOfWeek: number
  dayName: string
  multiple: boolean
  primary: boolean
}

export type DaysOff = {
  startDate: string
  endDate: string
  duration: number
}
export type Destination = "calendar" | "plan"
export type PlanCreatorMode = "create" | "edit"

export type PushPlanParams = {
  name: string
  historyId?: string
  mode: PlanCreatorMode
  destination: Destination
  saveCopyToPlans?: boolean
}
// export type FirstPrimaryEvent = {
//   eventDate: string
//   duration?: string
//   type?: string
//   weeksToEvent: number
//   dayOfWeek?: number
//   dayName?: string
//   primary?: boolean
//   multiple?: boolean
// }
