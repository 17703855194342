import { ftpToWatts, hrToBpm, getMinutesSeconds } from "@trainerday/cycling-converter"
import { SportType } from "@trainerday/ergdb-types"
import {
  rowingWattsToRowingPace,
  getMetersFromTimeAndPace
} from "@trainerday/cycling-converter/dist/converter/utils/rowingUtils"
import { select, event } from "d3-selection"
import { debounce } from "underscore"

export default {
  data() {
    return {
      hoverPolygons: null,
      messagesCursorEl: null,
      messagesCursorTextEl: null,
      messagesCursorPolyEl: null,
      tooltip: null,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      debouncedScaleHoverPoly: () => {}
    }
  },
  props: {
    showUpgradeMessage: {
      type: Boolean,
      default: false
    },
    showSegmentDescriptionPoints: {
      type: Boolean,
      default: false
    },
    tooltipZIndex: {
      type: Number,
      default: 170000
    },
    ftp: {
      type: Number,
      default: 0
    },
    thresholdHR: {
      type: Number,
      default: 0
    },
    workoutType: {
      type: String,
      default: SportType.BIKE
    },
    isHRSegments: {
      type: Boolean,
      default: false
    },
    segmentsSelectedRange: {
      type: Array,
      default: () => []
    },
    messagesCursor: {
      type: Array,
      default: () => []
    },
    clickablePolygons: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    width() {
      this.$nextTick(() => {
        this.removeHoverPolygons()
        this.initHoverPolygons()
      })
    },
    segments() {
      this.$nextTick(() => {
        this.removeHoverPolygons()
        this.initHoverPolygons()
      })
    },
    segmentsSelectedRange([start, end]) {
      this.activatePolygons(start, end)
    },
    messagesCursor([time, powerPercent, offset]) {
      this.setCaret(time, powerPercent, offset)
    }
  },
  computed: {
    descriptionCirclesData() {
      if (!this.showSegmentDescriptionPoints || !this.segments) {
        return []
      }
      return this.polyData.reduce((acc, curr) => {
        const [timeStart, valueStart, , , index] = curr[0]
        const descriptions = this.segments[index][6]
        if (descriptions && descriptions.length) {
          descriptions.forEach(([offset, text]) => {
            const descriptionTimeStart = offset ? timeStart + offset / 60 : timeStart
            acc.push([
              descriptionTimeStart, // x
              valueStart, // y
              text,
              getMinutesSeconds(descriptionTimeStart)
            ])
          })
        }
        return acc
      }, [])
    },

    polyData() {
      let nextPoly = false
      let buffer = []

      const polyData = this.segmentsData.reduce((acc, current) => {
        buffer.push(current)

        if (nextPoly) {
          acc.push(buffer)
          buffer = []
          nextPoly = false
        } else {
          nextPoly = true
        }
        return acc
      }, [])

      return polyData.reduce(
        (acc, [[timeStart, valueStart, cadenceStart, slope, index, , zoneData], [timeEnd, valueEnd, cadenceEnd]]) => {
          acc.push([
            [timeStart, valueStart, cadenceStart, slope, index, zoneData],
            [timeEnd, valueEnd, cadenceEnd],
            [timeEnd, 0, null],
            [timeStart, 0, null]
          ])
          return acc
        },
        []
      )
    }
  },
  methods: {
    setCaret(time, powerPercent, { minutes, seconds }) {
      const { x, y } = this

      const caretHalfWidth = 8

      if (time >= 0 && powerPercent) {
        this.messagesCursorEl &&
          this.messagesCursorEl
            .attr("transform", `translate(${x(time) - caretHalfWidth},${y(powerPercent + 20)})`)
            .style("visibility", "visible")
            .select("text")
            .text(`${minutes ? minutes + "m" : ""} ${seconds >= 0 ? seconds + "s" : ""}`)
      } else {
        this.messagesCursorEl && this.messagesCursorEl.style("visibility", "hidden")
      }
    },
    activatePolygons(start, end) {
      this.hoverPolygons &&
        this.hoverPolygons
          .selectAll("polygon")
          .style("opacity", 0)
          .style("fill", "rgba(255, 255, 255, 0.4)")

      if (start !== null && end !== null) {
        this.hoverPolygons &&
          this.hoverPolygons
            .selectAll("polygon")
            .filter(function(d, i) {
              return i >= start && i <= end
            })
            .style("fill", "red")
            .style("opacity", 1)
      }
    },

    tooltipTemplate([[timeStart, valueStart, cadenceStart, slope, , zoneData], [timeEnd, valueEnd, cadenceEnd]]) {
      let unitsStart
      let unitsEnd
      let unitsLabel
      let unitsPace
      let unitsMeters
      let showUnitsRow
      let zoneLabel

      if (this.isHRSegments) {
        unitsStart = hrToBpm(valueStart, this.thresholdHR)
        unitsEnd = hrToBpm(valueEnd, this.thresholdHR)
        unitsLabel = this.$t("common.bpm")
        showUnitsRow = this.thresholdHR > 0

        zoneLabel = zoneData ? zoneData.zone.label : null
      } else {
        unitsStart = ftpToWatts(valueStart, this.ftp)
        unitsEnd = ftpToWatts(valueEnd, this.ftp)
        unitsLabel = this.$t("common.watts")
        showUnitsRow = this.ftp > 0
        if (this.workoutType === SportType.ROWING && unitsStart) {
          const paceInSeconds = rowingWattsToRowingPace(unitsStart)
          unitsPace = getMinutesSeconds(paceInSeconds / 60)
          unitsMeters = getMetersFromTimeAndPace(timeEnd - timeStart, paceInSeconds)
        }
      }

      let slopeRow = ""
      let paceRow = ""
      let metersRow = ""
      let cadenceRow = ""
      let zoneRow = ""

      if (zoneLabel) {
        zoneRow = `${this.$t("common.zone")}: <b>${zoneLabel}</b>`
      }

      if (cadenceStart) {
        cadenceRow = `${this.$t("common.cadence")}: <b>${cadenceStart}</b>${
          cadenceEnd && cadenceEnd !== cadenceStart ? "<b>-" + cadenceEnd + "</b>" : ""
        }`
      }

      if (slope || slope === 0) {
        slopeRow = `${this.$t("common.slope")}: <b>${slope} %</b>`
      }

      if (unitsPace) {
        paceRow = `${this.$t("common.pace")}: <b>${unitsPace}</b>`
      }

      if (unitsMeters) {
        metersRow = `${this.$t("common.meters")}: <b>${unitsMeters}</b>`
      }

      const unitsRow = `${unitsLabel}: <b>${unitsStart}</b>${unitsEnd !== unitsStart ? "<b>-" + unitsEnd + "</b>" : ""}`
      const percentRow = `${this.$t("common.percent")}: <b>${valueStart}</b>${
        valueEnd !== valueStart ? "<b>-" + valueEnd + "</b>" : ""
      }`
      const unitsValuesText = showUnitsRow ? `${unitsRow}<br>${percentRow}` : percentRow
      const cadenceText = cadenceRow ? `${cadenceRow}<br>` : ""
      const slopeText = slopeRow ? `${slopeRow}<br>` : ""
      const paceText = paceRow ? `${paceRow}<br>` : ""
      const metersText = metersRow ? `${metersRow}<br>` : ""
      const zoneText = zoneRow ? `${zoneRow}<br>` : ""
      const startTimeText = `${this.$t("common.start_time")}: <b>${getMinutesSeconds(timeStart)} ${this.$t(
        "common.short_minutes"
      )}</b>`
      const durationTimeText = `${this.$t("common.duration")}: <b>${getMinutesSeconds(timeEnd - timeStart)} ${this.$t(
        "common.short_minutes"
      )}</b>`

      const upgradeMembershipText = `${percentRow}<br>${durationTimeText}<br><b>${this.$t(
        "common.upd_msp_to_see_values"
      )}</b>`
      const baseText = `${zoneText}${unitsValuesText}<br>${metersText}${paceText}${slopeText}${cadenceText}${startTimeText}<br>${durationTimeText}<br>`

      return this.showUpgradeMessage ? upgradeMembershipText : baseText
    },
    openTooltip(d, isOnlyDescription) {
      const gap = 2
      const tooltipHeight = this.tooltip.node().getBoundingClientRect().height
      const tooltipWidth = this.tooltip.node().getBoundingClientRect().width
      const position = event.pageX + gap
      const windowWidth = window.innerWidth

      const leftPosition = position + tooltipWidth >= windowWidth ? position - tooltipWidth - gap * 2 : position
      let template

      if (isOnlyDescription) {
        const [, , text, timeString] = d
        template = `${timeString}: <b class="comment">${text}</b>`
      } else {
        template = this.tooltipTemplate(d)
      }

      this.tooltip
        .html(template)
        .transition()
        .duration(0)
        .style("opacity", 0.9)
        .style("z-index", this.tooltipZIndex)
        .style("left", leftPosition + "px")
        .style("top", event.pageY - tooltipHeight - gap + "px")
    },
    removeHoverPolygons() {
      select(`#messages-cursor-${this.id}`).remove()
      select(`#hover-polygons-${this.id}`).remove()
      select(`#workout-chart-tooltip-${this.id}`).remove()
    },
    initHoverPolygons() {
      const { x, y } = this
      this.tooltip = select("body")
        .append("div")
        .attr("class", "workout-chart-tooltip")
        .attr("id", `workout-chart-tooltip-${this.id}`)
        .style("opacity", 0)

      const { tooltip, openTooltip, getActiveSegments, onClick, clickablePolygons } = this

      this.hoverPolygons = this.svg
        .append("g")
        .attr("class", "hover-polygons")
        .attr("id", `hover-polygons-${this.id}`)

      this.messagesCursorEl = this.svg
        .append("g")
        .attr("class", "messages-cursor")
        .attr("id", `messages-cursor-${this.id}`)
        .attr("transform", "translate(10, 10)")
        .attr("style", "visibility: hidden")

      this.messagesCursorTextEl = this.messagesCursorEl
        .append("text")
        .attr("class", "cursor-text")
        .attr("transform", "translate(-1,-3)")

      this.messagesCursorPolyEl = this.messagesCursorEl
        .append("text")
        .attr("class", "cursor-caret")
        .attr("points", "0,0 8,8 16,0")

      this.hoverPolygons
        .selectAll("polygon")
        .data(this.polyData)
        .enter()
        .append("polygon")
        .style("opacity", 0)
        .style("cursor", clickablePolygons ? "pointer" : "inherit")
        .style("fill", "rgba(255, 255, 255, 0.4)")
        .style("stroke", this.isHRSegments ? "#8199c9" : "")
        .attr("points", d => d.map(([time, value]) => [x(time), y(value)].join(",")).join(" "))
        .on("mousemove", function(d) {
          select(this).style("opacity", 0.9)

          openTooltip(d)
        })
        .on("mouseover", function(d) {
          select(this).style("opacity", 0.9)

          openTooltip(d)
        })
        .on("mouseout", function(d, i) {
          const [start = -1, end = -1] = getActiveSegments()

          if (Number.isInteger(start) && Number.isInteger(end) && i >= start && i <= end) {
            select(this).style("opacity", 1)
          } else {
            select(this).style("opacity", 0)
          }

          tooltip
            .transition()
            .duration(100)
            .style("opacity", 0)
        })
        .on("click", function(d, i) {
          onClick(d, i)
        })

      if (this.showSegmentDescriptionPoints) {
        this.hoverPolygons
          .selectAll("circle")
          .data(this.descriptionCirclesData)
          .enter()
          .append("circle")
          .style("cursor", "pointer")
          .style("fill", this.isHRSegments ? "rgb(161, 15, 16)" : "rgb(27 61 128)")
          .style("opacity", 0.8)
          .attr("cx", ([cx]) => x(cx))
          .attr("cy", y(8))
          .attr("r", "3")
          .on("mousemove", function(d) {
            select(this)
              .style("opacity", 1)
              .attr("r", "5")
            openTooltip(d, true)
          })
          .on("mouseover", function(d) {
            select(this)
              .style("opacity", 1)
              .attr("r", "5")

            openTooltip(d, true)
          })
          .on("mouseout", function() {
            select(this)
              .style("opacity", 0.8)
              .attr("r", "3")

            tooltip
              .transition()
              .duration(100)
              .style("opacity", 0)
          })
      }

      if (!this.clickablePolygons) return

      const [start, end] = this.segmentsSelectedRange
      this.activatePolygons(start, end)

      const [time, powerPercent, offset] = this.messagesCursor
      this.setCaret(time, powerPercent, offset)
    },
    getActiveSegments() {
      return this.segmentsSelectedRange
    },
    onClick(d, segmentNumber) {
      this.$emit("segment-clicked", segmentNumber)
    }
  },
  mounted() {
    select(window).on("scroll", () => {
      if (this.tooltip) {
        this.tooltip.style("opacity", 0)
      }
    })

    this.$nextTick(() => {
      this.initHoverPolygons()
    })

    this.debouncedScaleHoverPoly = debounce(() => {
      this.removeHoverPolygons()
      this.initHoverPolygons()
    }, 100)

    window.addEventListener("resize", this.debouncedScaleHoverPoly)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedScaleHoverPoly)
    this.removeHoverPolygons()
  }
}
