import { rowingWattsToRowingPace } from "@trainerday/cycling-converter/dist/converter/utils/rowingUtils"
import { wPrimeBalanceFroncioniSkibaClarke } from "@trainerday/cycling-metrics"
import { floatMinutesToMinSec, getMinutesSeconds, hrPercentToHeartRateZone } from "@trainerday/cycling-converter"
import { SportType, defaultHeartRateZones } from "@trainerday/ergdb-types"
import { getTimeArray } from "@/services/getTimeArray"
import { scaleLinear } from "d3-scale"
import { extent } from "d3-array"
import { area, line } from "d3-shape"

// const LABELS_COLOR = '#c2c2c2' // '#D7DADF'
const LINE_GRAY_DARK = "#aaa"
const LINE_GRAY_LIGHT = "#D7DADF"
const LINE_YELLOW = "#FFCF74"
const BACKGROUND_DARK = "#20232F"
const BACKGROUND_LIGHT = "#F9FAFE"
const LABELS_COLOR = "#2B2C2E"

const RPM_COLOR = "#fabe4d"
const BPM_COLOR = "#FF0000"

const FTP_SEGMENT_COLOR = "#2C68DE"
const HR_SEGMENT_COLOR = "#808080"
const OUTDOORS_SEGMENT_COLOR = "#144bb8"
const ZONE_COLORS = {
  Z1: "#a8e9c2",
  Z2: "#b1d3f3",
  Z3: "#c3abf2",
  Z4: "#ffe2b2",
  Z5: "#ffaead",
  Z6: "#7f7f7f"
}

export default class Chart {
  constructor(props) {
    const {
      segments,
      watts,
      bpm,
      rpm,
      chartType = "default",
      workoutType = "bike",
      criticalPower,
      wPrime,
      canvas,
      width,
      height,
      maxValueY,
      cursorLineX = null,
      theme = "light",
      showWattsDiagram = false,
      showFTPLine = true,
      showHRDiagram = false,
      showRPMDiagram = false,
      showWBal = false,
      showYLines = true,
      segmentColorScheme = "default", // segments color
      axisBackgroundColor = "#F4F5F9",
      backgroundColor,
      zones
    } = props

    this.isMiniChart = chartType === "mini"
    this.isMobileSize = width <= 250
    this.segments = segments
    this.watts = watts
    this.pace = []
    this.bpm = bpm
    this.rpm = rpm
    this.criticalPower = criticalPower
    this.wPrime = wPrime
    this.showWattsDiagram = showWattsDiagram
    this.showHRDiagram = showHRDiagram
    this.showRPMDiagram = showRPMDiagram
    this.showFTPLine = showFTPLine
    this.showWBal = showWBal
    this.showYLines = showYLines
    this.isDarkTheme = theme === "dark"
    this.segmentColorScheme = ["default", "hr", "outdoor"].includes(segmentColorScheme) ? segmentColorScheme : "default"
    this.isRowing = workoutType === SportType.ROWING && this.watts
    this.isHasSegments = segments && segments.length
    this.workoutType = workoutType
    this.padding = {
      top: 0,
      right: 0,
      bottom: this.isMiniChart ? 0 : this.isMobileSize ? 10 : 27,
      left: this.isMiniChart ? 0 : this.isMobileSize ? 12 : 27
    }
    this.width = width
    this.height = height
    this.cursorLineX = cursorLineX !== null ? cursorLineX - this.padding.left : null
    this.axisBackgroundColor = axisBackgroundColor
    this.backgroundColor = backgroundColor || (this.isDarkTheme ? BACKGROUND_DARK : BACKGROUND_LIGHT)
    this.zones = zones || defaultHeartRateZones
    if (this.isRowing) {
      this.pace = this.watts.map((w, i) => getMinutesSeconds(rowingWattsToRowingPace(w || this.watts[i - 1] || 0) / 60))
    }

    const isMaxValueYNotSet = !maxValueY

    this.maxValueY = isMaxValueYNotSet ? 200 : maxValueY
    this.canvas = canvas
    this.context = this.canvas.getContext("2d")
    const { devicePixelRatio: scale = 1 } = window
    canvas.width = Math.floor(width * scale)
    canvas.height = Math.floor(height * scale)

    this.context.scale(scale, scale)
    this.drawChart()
  }

  async getData() {
    this.context.fillStyle = this.isDarkTheme ? BACKGROUND_DARK : BACKGROUND_LIGHT
    return this.canvas
  }

  get x() {
    // if you remove segmentsData below it wouldn't work
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const { width, padding, segmentsData } = this

    return scaleLinear()
      .domain(extent([0, this.maxTimeValue]))
      .range([padding.left, width - padding.right])
  }

  get y() {
    const { height, maxValueY, padding } = this
    const extraTop = (height - padding.bottom - padding.top) / 9 // 8 lines + 1 extra

    return scaleLinear()
      .domain([0, maxValueY])
      .range([height - padding.bottom, padding.top + extraTop])
  }

  get cursorX() {
    let maxTimeInMin
    if (this.cursorLineX === null || !this.segments || !this.segments.length) {
      return 0
    }

    if (this.segments.length === 1) {
      const [minutes] = this.segments[0]
      maxTimeInMin = minutes
    } else {
      maxTimeInMin = this.segments.reduce((prev, curr) => (prev[0] || prev) + (curr[0] || 0))
    }

    const maxTimeInSec = maxTimeInMin * 60
    const pixelsPerSecond = this.chartWidth / maxTimeInSec
    return this.cursorLineX / pixelsPerSecond
  }

  get chartWidth() {
    const { padding, width } = this
    return width ? width - padding.right - padding.left : 0
  }

  get linesY() {
    const { x: xFunc, y, chartWidth, maxValueY, isDarkTheme, height } = this
    const middle = maxValueY / 2

    const m = middle
    const mVY = maxValueY

    const width = chartWidth
    const x = xFunc(0)

    const fill = isDarkTheme ? LINE_GRAY_DARK : LINE_GRAY_LIGHT
    const isDetailed = height >= 200

    const boldLineHeight = 1.2
    const mediumLineHeight = 0.6
    const thinLineHeight = 0.6

    const yMin = y(0)
    const yCenter = y(m)
    const yMax = y(maxValueY - mediumLineHeight)

    return [
      { width, x, y: yMax, height: mediumLineHeight, fill },
      isDetailed ? { width, x, y: y(m * 1.75), height: thinLineHeight, fill } : null,
      { width, x, y: y(middle * 1.5), height: mediumLineHeight, fill },
      isDetailed ? { width, x, y: y(middle * 1.25), height: thinLineHeight, fill } : null,
      { width, x, y: yCenter, height: boldLineHeight, fill: this.showFTPLine ? LINE_YELLOW : fill },
      isDetailed ? { width, x, y: y(mVY - m * 1.25), height: thinLineHeight, fill } : null,
      { width, x, y: y(mVY - m * 1.5), height: mediumLineHeight, fill },
      isDetailed ? { width, x, y: y(mVY - m * 1.75), height: thinLineHeight, fill } : null,
      { width, x, y: yMin, height: mediumLineHeight, fill }
    ].filter(e => e)
  }

  get segmentsData() {
    this.timeAcc = 0

    if (!this.isHasSegments) {
      return []
    }

    let color = this.segmentColorScheme === "outdoor" ? OUTDOORS_SEGMENT_COLOR : FTP_SEGMENT_COLOR
    return this.segments.reduce((acc, [time, start, end]) => {
      let zoneData = null

      // define zone and color
      if (this.segmentColorScheme === "hr") {
        if (this.zones.length) {
          zoneData = hrPercentToHeartRateZone(start, this.zones)
          color = ZONE_COLORS[`Z${zoneData.zoneNumber}`]
        } else {
          color = HR_SEGMENT_COLOR
        }
      }

      acc.push([this.timeAcc, start, color, zoneData])
      this.timeAcc = this.timeAcc + time
      acc.push([this.timeAcc, end || start, color, zoneData])
      return acc
    }, [])
  }

  get wBalLineData() {
    if (!this.showWBal) {
      return []
    }
    const { watts, criticalPower, wPrime } = this
    const wPrimeBalance = wPrimeBalanceFroncioniSkibaClarke(watts, criticalPower, wPrime)
    return wPrimeBalance.map((power, currentSecond) => {
      return [currentSecond, power / 100]
    })
  }

  get maxTimeValue() {
    const actualTime = this.watts ? this.watts.length + 1 : 0
    return actualTime > this.timeAcc * 60 ? actualTime : this.timeAcc * 60
  }

  get fontSize() {
    return this.isMobileSize ? 6 : 12
  }

  get labelsY() {
    const { x: xFunc, y } = this
    const x = xFunc(0)

    const { maxValueY } = this

    const middle = maxValueY / 2

    return [
      { y: y(0) },
      { y: y(middle * 0.5) },
      { y: y(middle) },
      { y: y(maxValueY - middle * 0.5) },
      { y: y(maxValueY) }
    ]
      .reduce((labels, label, index) => {
        label.x = x
        label.fill = this.isDarkTheme ? "#ffffff" : LABELS_COLOR

        if (index === 0) {
          label.value = 0
          labels.push(label)
          return labels
        }

        if (labels.length === 4) {
          label.value = maxValueY
          labels.push(label)
          return labels
        }

        labels.push({
          ...label,
          value: Math.round(maxValueY / 4 + labels[labels.length - 1].value)
        })

        return labels
      }, [])
      .filter(l => l.value)
  }

  get labelsX() {
    const { maxTimeValue } = this
    const { x: xFunc } = this

    const labels = getTimeArray(maxTimeValue / 60, 1)
    const preLastValue = labels[labels.length - 2]
    const lastValue = labels[labels.length - 1]
    if (lastValue < preLastValue) {
      labels.pop()
    }

    const half = labels.length && labels.length >= 2 ? (labels[1] - labels[0]) / 2 : 0

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return labels.reduce((acc, label, index) => {
      const nameLabel = parseFloat(Number(label).toFixed(1)).toString()

      let x = xFunc(label * 60)

      acc.push({
        name: nameLabel,
        x
      })

      x = xFunc((label + half) * 60)

      if (x <= this.width && half) {
        acc.push({
          x
        })
      }

      return acc
    }, [])
  }

  drawLabelsY() {
    this.labelsY.forEach(label => {
      const { context } = this

      context.font = `${this.fontSize}px Poppins`
      context.textAlign = "center"
      context.textBaseline = "middle"
      context.fillStyle = label.fill

      context.fillText(label.value, label.x - this.padding.left / 2, label.y)
    })
  }

  drawLabelsX() {
    this.labelsX.forEach((label, i) => {
      const { context, cursorX } = this
      const thickHeight = this.isMobileSize ? 3 : 7
      const thickPosY = this.height - this.padding.bottom - thickHeight
      const cursorScaledX = this.x(cursorX)

      const isLastLabel = this.labelsX.length - 1 === i
      const isCursorNear = cursorScaledX > label.x - 20 && cursorScaledX < label.x + 20

      context.fillStyle = this.isDarkTheme ? "#ffffff" : LABELS_COLOR
      context.fillRect(!isLastLabel ? label.x : label.x - 1.5, thickPosY, 1, thickHeight)

      if (label.name) {
        const text = this.context.measureText(label.name)

        let textAlign = "center"

        if (this.width - label.x - text.width / 2 <= 0) {
          textAlign = "end"
        }
        context.font = `${this.fontSize}px Poppins`
        context.textAlign = textAlign
        context.textBaseline = "middle"
        if (isCursorNear) {
          context.fillStyle = "#eee"
        }
        context.fillText(label.name, label.x, thickPosY + (this.isMobileSize ? 9 : 20))
      }
    })
  }

  drawRPMAreaPath() {
    const { x, y } = this
    return line()
      .x((value, time) => x(time))
      .y(value => y(value))
      .context(this.context)(this.rpm)
  }

  drawHRAreaPath() {
    const { x, y } = this
    return line()
      .x((value, time) => x(time))
      .y(value => y(value))
      .context(this.context)(this.bpm)
  }

  drawWattsAreaPath() {
    const { x, y } = this
    return line()
      .x((value, time) => x(time))
      .y(value => y(value))
      .context(this.context)([0, ...this.watts, 0])
  }

  drawSegments() {
    const { x, y } = this
    if (this.segmentColorScheme === "hr") {
      this.segmentsData.forEach(([timeStart, powerStart, color], i, arr) => {
        const even = i % 2
        if (even) {
          return
        }
        const [timeEnd, powerEnd] = arr[i + 1]

        this.context.beginPath()
        this.context.fillStyle = color
        area()
          .x(([time]) => x(time * 60))
          .y0(y(0))
          .y1(([, value]) => y(value))
          .context(this.context)([
          [timeStart, powerStart],
          [timeEnd, powerEnd]
        ])
        this.context.fill()
      })

      this.context.save()
    } else {
      this.context.beginPath()
      if (this.segmentColorScheme === "outdoor") {
        this.context.fillStyle = OUTDOORS_SEGMENT_COLOR
      }

      if (this.segmentColorScheme === "default") {
        this.context.fillStyle = FTP_SEGMENT_COLOR
      }

      area()
        .x(([time]) => x(time * 60))
        .y0(y(0))
        .y1(([, value]) => y(value))
        .context(this.context)(this.segmentsData)

      this.context.fill()
      this.context.save()
    }

    // Draw selected segment under the cursor
    if (this.cursorLineX && this.cursorLineX > 0) {
      const cursorMinutes = this.cursorX / 60
      const reversedSegmentsData = [...this.segmentsData].reverse()
      const segmentDataIndex = reversedSegmentsData.findIndex(([time]) => cursorMinutes > time)
      if (segmentDataIndex !== -1) {
        const [startTime, start] = reversedSegmentsData[segmentDataIndex]
        const [endTime, end] = reversedSegmentsData[segmentDataIndex - 1]
        if (startTime !== undefined && endTime !== undefined) {
          this.context.beginPath()
          area()
            .x(([t]) => this.x(t * 60))
            .y0(this.y(0))
            .y1(([, val]) => this.y(val))
            .context(this.context)([
            [startTime, start],
            [endTime, end || start]
          ])
          this.context.fillStyle = "#033292"
          this.context.fill()
          this.context.save()
        }
      }
    }
  }

  drawRPMArea() {
    this.context.beginPath()
    this.drawRPMAreaPath()
    this.context.strokeStyle = RPM_COLOR
    this.context.lineWidth = 1
    this.context.stroke()
    this.context.save()
  }

  drawHRArea() {
    this.context.beginPath()
    this.drawHRAreaPath()
    this.context.strokeStyle = BPM_COLOR
    this.context.lineWidth = 1
    this.context.stroke()
    this.context.save()
  }

  drawWattsArea() {
    this.context.beginPath()
    this.drawWattsAreaPath()
    this.context.fillStyle =
      this.segments && this.segments.length ? "rgba(255, 255, 255, 0.35)" : "rgb(44, 104, 222, 0.1)"
    this.context.strokeStyle = "rgb(17,29,104)"
    this.context.lineWidth = 1
    this.context.fill()
    this.context.stroke()
    this.context.save()
  }

  drawLine(line) {
    this.context.beginPath()
    this.context.strokeStyle = line.fill
    this.context.lineWidth = line.height
    this.context.moveTo(line.x, line.y)
    this.context.lineTo(this.width - this.padding.right, line.y)
    this.context.stroke()
    this.context.restore()
  }

  drawWBalLineArea() {
    this.context.beginPath()
    this.context.setLineDash([4, 4])
    this.drawWBalLineAreaPath()
    this.context.strokeStyle = "rgb(236,6,6)"
    this.context.lineWidth = 2
    this.context.stroke()
    this.context.save()
  }

  drawWBalLineAreaPath() {
    const { height, width, padding } = this

    const x = scaleLinear()
      .domain(extent(this.wBalLineData, ([time]) => time))
      .range([padding.left, width - padding.right])

    const wBalMaxValue = this.wPrime / 100

    const y = scaleLinear()
      .domain([0, wBalMaxValue])
      .range([height - padding.bottom, padding.top])

    return line()
      .x(([time]) => x(time))
      .y(([, value]) => y(value))
      .context(this.context)(this.wBalLineData)
  }

  drawCursor() {
    const { x, y, watts, bpm, rpm, cursorX, context, height, width, padding, fontSize, isRowing, pace } = this
    const { showHRDiagram, showRPMDiagram, showWattsDiagram } = this

    const isDrawWatts = showWattsDiagram && watts.length
    const isDrawRowing = isRowing && pace.length
    const isDrawHR = showHRDiagram && bpm.length
    const isDrawRPM = showRPMDiagram && rpm.length

    const offsetFromLine = 20
    const scaledX = x(cursorX)
    const isNearEnd = scaledX > width - 120
    const titleX = isNearEnd ? scaledX - 100 : scaledX + offsetFromLine

    let lineY = 45
    let backgroundBoxHeight = 0

    if (isDrawWatts) {
      backgroundBoxHeight = 28
      if (isDrawRowing) {
        backgroundBoxHeight += 15
      }
    } else {
      backgroundBoxHeight = 8
    }

    if (isDrawHR) {
      backgroundBoxHeight += 20
    }

    if (isDrawRPM) {
      backgroundBoxHeight += 20
    }

    // draw vertical line
    context.beginPath()
    context.strokeStyle = LINE_YELLOW
    context.lineWidth = 1
    context.moveTo(scaledX, 0)
    context.lineTo(scaledX, height - padding.bottom)
    context.stroke()
    context.closePath()

    // draw box background and titles
    if (isDrawWatts || isDrawHR || isDrawRPM) {
      context.beginPath()
      context.fillStyle = "rgb(44 104 222 / 10%)"
      context.strokeStyle = "rgb(44 104 222 / 30%)"
      context.lineWidth = 1
      context.rect(titleX - 10, 30, 98, backgroundBoxHeight)
      context.stroke()
      context.fill()
      context.closePath()

      if (isDrawWatts) {
        const currentWatts = watts[Math.round(cursorX)]
        const scaledY = y(currentWatts)
        if (typeof currentWatts === "number") {
          // draw circle
          context.beginPath()
          context.fillStyle = "#fff"
          context.strokeStyle = LABELS_COLOR
          context.lineWidth = 5
          context.arc(scaledX, scaledY, 4, 0, Math.PI * 2, true)
          context.stroke()
          context.fill()
          context.closePath()

          // draw watts title
          context.beginPath()
          context.fillStyle = "rgba(17, 29, 104, 1)"
          context.arc(titleX, lineY, 3, 0, Math.PI * 2, true)
          context.fill()
          context.closePath()

          context.beginPath()
          context.fillStyle = BACKGROUND_DARK
          context.font = "600 12px Poppins"
          context.textAlign = "left"
          context.fillText("Watts:", titleX + 8, lineY)
          context.font = "300 12px Poppins"
          context.fillText(currentWatts, titleX + 8 + 45, lineY)
          context.closePath()

          lineY += 20

          if (isDrawRowing) {
            lineY -= 5
            const currentPace = pace[Math.round(cursorX)]

            // draw pace label
            context.beginPath()
            context.fillStyle = BACKGROUND_DARK
            context.font = "600 12px Poppins"
            context.textAlign = "left"
            context.fillText("Pace:", titleX + 8, lineY)
            context.font = "300 12px Poppins"
            context.fillText(currentPace, titleX + 8 + 40, lineY)
            context.closePath()
            lineY += 20
          }
        }
      }

      if (isDrawHR) {
        const currentBbm = bpm[Math.round(cursorX)]
        const scaledY = y(currentBbm)

        if (typeof currentBbm === "number") {
          // draw circle
          context.beginPath()
          context.fillStyle = "#fff"
          context.strokeStyle = BPM_COLOR
          context.lineWidth = 5
          context.arc(scaledX, scaledY, 4, 0, Math.PI * 2, true)
          context.stroke()
          context.fill()
          context.closePath()

          // draw hr label
          context.beginPath()
          context.fillStyle = BPM_COLOR
          context.arc(titleX, lineY, 3, 0, Math.PI * 2, true)
          context.fill()
          context.closePath()

          context.beginPath()
          context.fillStyle = BACKGROUND_DARK
          context.font = "600 12px Poppins"
          context.textAlign = "left"
          context.fillText("BPM:", titleX + 8, lineY)
          context.font = "300 12px Poppins"
          context.fillText(currentBbm, titleX + 8 + 35, lineY)
          context.closePath()
          lineY += 20
        }
      }

      if (isDrawRPM) {
        const currentRpm = rpm[Math.round(cursorX)]
        const scaledY = y(currentRpm)

        if (typeof currentRpm === "number") {
          // draw circle
          context.beginPath()
          context.fillStyle = "#fff"
          context.strokeStyle = RPM_COLOR
          context.lineWidth = 5
          context.arc(scaledX, scaledY, 4, 0, Math.PI * 2, true)
          context.stroke()
          context.fill()
          context.closePath()

          // draw hr label
          context.beginPath()
          context.fillStyle = RPM_COLOR
          context.arc(titleX, lineY, 3, 0, Math.PI * 2, true)
          context.fill()
          context.closePath()

          context.beginPath()
          context.fillStyle = BACKGROUND_DARK
          context.font = "600 12px Poppins"
          context.textAlign = "left"
          context.fillText("RPM:", titleX + 8, lineY)
          context.font = "300 12px Poppins"
          context.fillText(currentRpm, titleX + 8 + 35, lineY)
          context.closePath()
        }
      }
    }

    // draw timeline
    const offsetFromLineInTimeline = 20
    const timelineX = scaledX > width - 50 ? scaledX - offsetFromLineInTimeline : scaledX
    let minutes
    let seconds
      // eslint-disable-next-line prefer-const
    ;({ minutes, seconds } = floatMinutesToMinSec(cursorX / 60))
    seconds = `0${seconds}`.slice(-2)
    const timelineTitle = `${minutes}:${seconds}`

    context.beginPath()
    context.fillStyle = LABELS_COLOR
    context.font = `${fontSize}px Poppins`
    context.textAlign = "center"
    context.textBaseline = "middle"
    context.fillText(timelineTitle, timelineX, height - padding.bottom / 2 - 1)
    context.closePath()
  }
  drawLines() {
    this.context.fillStyle = this.backgroundColor
    this.context.fillRect(0, 0, this.width, this.height)

    this.context.fillStyle = "#E2E3E3"
    const a = 4
    this.context.fillRect(0, (this.height / a) * 1, this.width, 0.7)

    this.context.fillStyle = "#FFCF74"
    this.context.fillRect(0, (this.height / a) * 2, this.width, 0.82)

    this.context.fillStyle = "#E2E3E3"
    this.context.fillRect(0, (this.height / a) * 3, this.width, 0.7)
  }
  reDrawChart(cursorLineX, showWattsDiagram, showHRDiagram, showRPMDiagram) {
    this.showWattsDiagram = showWattsDiagram !== undefined ? showWattsDiagram : this.showWattsDiagram
    this.showHRDiagram = showHRDiagram !== undefined ? showHRDiagram : this.showHRDiagram
    this.showRPMDiagram = showRPMDiagram !== undefined ? showRPMDiagram : this.showRPMDiagram
    this.cursorLineX = cursorLineX ? cursorLineX - this.padding.left : null
    this.context.clearRect(0, 0, this.width, this.height)
    this.drawChart()
    return { cursorX: this.cursorX }
  }

  drawChart() {
    if (!this.isMiniChart) {
      this.context.fillStyle = this.backgroundColor
    } else {
      this.context.fillStyle = "#FFFFFF"
    }
    this.context.fillRect(0, 0, this.width, this.height)

    if (this.showYLines !== false) {
      this.linesY.forEach(e => {
        this.drawLine(e)
      })
    }
    this.drawLines()
    if (this.isHasSegments) {
      this.drawSegments()
    }

    this.context.fillStyle = this.isDarkTheme ? BACKGROUND_DARK : this.axisBackgroundColor

    if (!this.isMiniChart) {
      this.context.fillRect(0, 0, this.padding.left, this.height)
      this.context.fillRect(0, 0, 0, 0)
      this.context.fillRect(0, this.height - this.padding.bottom, this.width, this.padding.bottom)
    }

    if (this.showWattsDiagram) {
      this.drawWattsArea()
    }

    if (this.showHRDiagram) {
      this.drawHRArea()
    }

    if (this.showRPMDiagram) {
      this.drawRPMArea()
    }

    if (this.showWBal) {
      this.drawWBalLineArea()
    }

    if (!this.isMiniChart) {
      this.drawLabelsY()
      this.drawLabelsX()
    }

    if (this.cursorLineX && this.cursorLineX > 0) {
      this.drawCursor()
    }
  }
}
