import { debounce } from "underscore"
import Chart from "@/services/DrawChart"

export default {
  data() {
    return {
      canvasPayload: null,
      chartInstance: null,
      cursorLineX: null,
      cursorMouseSec: null,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      debouncedResize: () => {}
    }
  },
  mounted() {
    // this.$nextTick(() => this.renderCanvasChart())

    this.debouncedResize = debounce(() => {
      this.renderCanvasChart()
    }, 100)

    window.addEventListener("resize", this.debouncedResize)
  },
  beforeDestroy() {
    const { canvas } = this.$refs
    window.removeEventListener("resize", this.debouncedResize)
    if (canvas) {
      canvas.removeEventListener("mousemove", this.onMouseMove)
      canvas.removeEventListener("mouseleave", this.onMouseLeave)
    }
  },
  watch: {
    segments() {
      this.addMouseEvent()
      this.renderCanvasChart()
    },
    criticalPower() {
      this.renderCanvasChart()
    },
    wPrime() {
      this.renderCanvasChart()
    },
    showWBal() {
      this.renderCanvasChart()
    },
    showHRDiagram() {
      this.chartInstance.reDrawChart(this.cursorLineX, this.showWattsDiagram, this.showHRDiagram, this.showRPMDiagram)
    },
    showRPMDiagram() {
      this.chartInstance.reDrawChart(this.cursorLineX, this.showWattsDiagram, this.showHRDiagram, this.showRPMDiagram)
    },
    showWattsDiagram() {
      this.chartInstance.reDrawChart(this.cursorLineX, this.showWattsDiagram, this.showHRDiagram, this.showRPMDiagram)
    }
  },
  methods: {
    onMouseMove({ clientX }) {
      const { canvas } = this.$refs
      const { left } = canvas.getBoundingClientRect()
      this.cursorLineX = Math.abs(clientX - left)
      const { cursorX } = this.chartInstance.reDrawChart(this.cursorLineX)
      this.cursorMouseSec = Math.round(cursorX) // current mouse second
    },

    onMouseLeave() {
      this.cursorLineX = null
      this.chartInstance.reDrawChart(this.cursorLineX)
      this.cursorMouseSec = null
    },

    addMouseEvent() {
      const { $refs, showCursorLine } = this
      const { canvas } = $refs

      if (!canvas || !showCursorLine) {
        return
      }

      canvas.addEventListener("mousemove", this.onMouseMove)
      canvas.addEventListener("mouseleave", this.onMouseLeave)
    },

    renderCanvasChart(payload) {
      if (payload) {
        this.canvasPayload = { ...payload }
      }
      const data = this.canvasPayload || this
      const { $refs, cursorLineX } = this

      const {
        ftp,
        segments,
        watts,
        bpm,
        rpm,
        chartType,
        criticalPower,
        wPrime,
        maxValueY,
        showWattsDiagram,
        showDarkDiagram,
        showHRDiagram,
        showRPMDiagram,
        showFTPLine,
        showWBal,
        showCursorLine,
        showYLines,
        axisBackgroundColor,
        backgroundColor,
        segmentColorScheme,
        workoutType
      } = data

      const { canvas } = $refs

      if (!canvas) {
        return
      }

      canvas.style = "width: 100%; height: 100%;"

      const { width, height } = canvas.getBoundingClientRect()
      // eslint-disable-next-line no-new
      this.chartInstance = new Chart({
        theme: showDarkDiagram ? "dark" : "light",
        segments,
        watts,
        bpm,
        rpm,
        chartType,
        workoutType,
        criticalPower,
        wPrime,
        showWattsDiagram,
        showHRDiagram,
        showRPMDiagram,
        showFTPLine,
        showWBal,
        showYLines,
        canvas,
        width,
        height,
        maxValueY: maxValueY || ftp * 2,
        cursorLineX: showCursorLine ? cursorLineX : null,
        axisBackgroundColor,
        backgroundColor,
        segmentColorScheme: segmentColorScheme || "default",
        zones:
          this.tdUser.calculationsSettings && this.tdUser.calculationsSettings.heartRateZones
            ? this.tdUser.calculationsSettings.heartRateZones
            : []
      })
      const { cursorX } = this.chartInstance
      this.cursorMouseSec = Math.round(cursorX) // current mouse second

      if (!showCursorLine) {
        this.chartInstance = null
      }
    }
  }
}
