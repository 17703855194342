import StepWelcome from "@/views/jack/StepWelcome.vue"
import StepName from "@/views/jack/StepName.vue"
import StepFtp from "@/views/jack/StepFtp.vue"
import StepQuestioning from "@/views/jack/StepQuestioning.vue"
import StepSummary from "@/views/jack/StepSummary.vue"
import PlanCreator from "@/views/jack/PlanCreator.vue"
import { ROUTE_NAMES } from "@/defaults"

export default [
  {
    path: "/jack",
    component: StepWelcome,
    name: ROUTE_NAMES.WELCOME,
    meta: {
      isGrayed: true
    }
  },
  {
    path: "/jack/whats-your-name",
    name: ROUTE_NAMES.WHATS_YOUR_NAME,
    component: StepName,
    meta: {
      isGrayed: true
    }
  },
  {
    path: "/jack/whats-your-ftp",
    name: ROUTE_NAMES.WHATS_YOUR_FTP,
    component: StepFtp,
    meta: {
      isGrayed: true
    }
  },
  {
    path: "/jack/questioning",
    name: ROUTE_NAMES.QUESTIONING,
    component: StepQuestioning
  },
  {
    path: "/jack/summary",
    name: ROUTE_NAMES.SUMMARY,
    component: StepSummary,
    meta: {
      jackSays: "Hey %FIRSTNAME%, here’s my assessment of your Strava training history."
    }
  },
  {
    path: "/jack/plan/:mode",
    name: ROUTE_NAMES.PLAN,
    component: PlanCreator
  },
]
